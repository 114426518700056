<template>
  <ion-grid class="ion-padding-top">
    <ion-row v-if="!anagProspectFilters">
      <ion-col> Caricamento dei filtri in corso... </ion-col>
    </ion-row>
    <ion-row v-else>
      <ion-col>
        <ion-select
          placeholder="Regione"
          interface="popover"
          v-model="anagProspectFiltersselected.regione"
          @ionChange="getAnagProspectFilters({ from: 'regione' })"
        >
          <ion-select-option
            v-for="regione in anagProspectFilters.regioni"
            :key="regione"
            :value="regione"
            >{{ regione }}</ion-select-option
          >
        </ion-select>
      </ion-col>
      <ion-col>
        <ion-select
          placeholder="Provincia"
          interface="popover"
          v-model="anagProspectFiltersselected.provincia"
          @ionChange="getAnagProspectFilters({ from: 'procinvia' })"
        >
          <ion-select-option
            v-for="provincia in anagProspectFilters.province"
            :key="provincia"
            :value="provincia"
            >{{ provincia }}</ion-select-option
          >
        </ion-select>
      </ion-col>
      <ion-col>
        <ion-select
          placeholder="Città"
          interface="popover"
          v-model="anagProspectFiltersselected.citta"
          @ionChange="getAnagProspectFilters({ from: 'main' })"
        >
          <ion-select-option
            v-for="citta in anagProspectFilters.citta"
            :key="citta"
            :value="citta"
            >{{ citta }}</ion-select-option
          >
        </ion-select>
      </ion-col>
      <ion-col v-if="showFiltroAgente">
        <ion-select
          placeholder="Agente"
          interface="popover"
          v-model="anagProspectFiltersselected.agente"
          @ionChange="getAnagProspectFilters({ from: 'main' })"
        >
          <ion-select-option
            v-for="agente in anagProspectFilters.agenti"
            :key="agente.id"
            :value="agente.id"
            >{{ agente.nome }}</ion-select-option
          >
        </ion-select>
      </ion-col>
    </ion-row>
    <ion-row class="justify-content-end">
      <ion-col size="auto">
        <button
          type="button"
          class="p-sm button-secondary"
          @click="svuotaFiltri"
        >
          Svuota filtri
        </button>
      </ion-col>
      <ion-col size="auto">
        <button type="button" class="p-sm button-secondary" @click="cerca">
          Cerca
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from "vue";
import ApiService from "@/common/service.api";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { useGeneralStore } from "@/stores/useGeneralStore.js";

const generalStore = useGeneralStore();

const props = defineProps({
  showFiltroAgente: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits([
  "update:anagProspectFilters",
  "reset:anagProspectFilters",
  "cerca:anagProspectFilters",
]);

const anagProspectFiltersselected = ref({
  regione: "all",
  provincia: "all",
  citta: "all",
  agente: null,
});

const anagProspectFilters = ref({
  agenti: [],
  citta: "all",
  provincia: "all",
  regione: "all",
});

function cerca() {
  emits("cerca:anagProspectFilters");
}

function svuotaFiltri() {
  anagProspectFiltersselected.value = {
    regione: "all",
    provincia: "all",
    citta: "all",
    agente: null,
  };

  getAnagProspectFilters({ from: "main" });
  emits("reset:anagProspectFilters");
}

function getAnagProspectFilters({ from }) {
  const url = `Ecommerce/GetAnagProspectFilters/${anagProspectFiltersselected.value.regione}/${anagProspectFiltersselected.value.provincia}`;

  switch (from) {
    case "regione":
      anagProspectFiltersselected.value.provincia = "all";
      anagProspectFiltersselected.value.citta = "all";
      break;
    case "provincia":
      anagProspectFiltersselected.value.citta = "all";
      break;
    default:
      break;
  }

  ApiService.get(url)
    .then((res) => {
      anagProspectFilters.value = res.data;
    })
    .finally(() => {
      emits("update:anagProspectFilters", anagProspectFiltersselected.value);
    });

  generalStore.setYoubeFilter({
    regione: anagProspectFiltersselected.value.regione,
    provincia: anagProspectFiltersselected.value.provincia,
    citta: anagProspectFiltersselected.value.citta,
    agente: anagProspectFiltersselected.value.agente,
  });
}

onMounted(() => {
  if (generalStore.youbeFilter.regione != "all") {
    anagProspectFiltersselected.value.regione =
      generalStore.youbeFilter.regione;
  }

  if (generalStore.youbeFilter.provincia != "all") {
    anagProspectFiltersselected.value.provincia =
      generalStore.youbeFilter.provincia;
  }

  if (generalStore.youbeFilter.citta != "all") {
    anagProspectFiltersselected.value.citta = generalStore.youbeFilter.citta;
  }

  if (generalStore.youbeFilter.agente != null) {
    anagProspectFiltersselected.value.agente = generalStore.youbeFilter.agente;
  }

  getAnagProspectFilters({ from: "main" });
});
</script>
